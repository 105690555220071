import React, {useContext, useEffect} from 'react';
import "../css/FavCompareCard.css";
import {propertyConst} from "../constant/propertyConst";
import {appSettings} from "../constant/appConst";
import {appUtil} from "../constant/appUtil";
import FavCompareContext from "./context/FavCompareContext";
import {Link} from "react-router-dom";
import {apiConst} from "../constant/apiConst";
import {useTranslation} from "react-i18next";
function FavCompareCard({fav}) {
    const {favCompareList,setFavCompareList} = useContext(FavCompareContext);
    const { t } = useTranslation();
    function getImage(){
        let thumbs = propertyConst.thumb[fav.property.room_type_folder];
        return (thumbs[0].source);
    }


    function onClickClose(){

        let newFavCompareList = [...favCompareList];
        favCompareList.forEach((item,i) => {
            if(item === fav) {
                newFavCompareList.splice(i,1)
            }
        })
        setFavCompareList(newFavCompareList);

    }

    return (
        <div className={"compare-list-item"} >
            <button className="close-button colored" onClick={onClickClose}>
                <div></div>
                <div></div>
            </button>
            <div className="compare-list-item-img">
                <img className="img-fluid" src={getImage()} alt=""/>
            </div>
            <div className="compare-list-item-text">
                <div>
                    <span>{t("favorites.number")}</span>
                    <span>{fav.property.number}</span>
                </div>
                <div>
                    <span>{t("favorites.price")}</span>
                    <span>{appUtil.formatPrice(fav.property.price)}</span>
                </div>
                <div>
                    <span>{t("favorites.floor_type")}</span>
                    <span>{fav.property.room_type.name}</span>
                </div>
                <div>
                    <span>{t("favorites.net_area")}</span>
                    <span>{fav.property.net_area} {appSettings.areaUnit}</span>
                </div>

                <div>
                    <span>{t("favorites.balcony_area")}</span>
                    <span>{fav.property.terrace_area} {appSettings.areaUnit}</span>
                </div>

                <div>
                    <span>{t("favorites.bath")}</span>
                    <span>{fav.property.bathrooms} </span>
                </div>

            </div>
            <div className="compare-list-item-button">
                <Link to={"/property-view/" + fav.property.id} className={"btn-review-details text-uppercase"} ><span className="icon"></span>{t("favorites.review_in_details")}</Link>
            </div>

        </div>
    );
}

export default FavCompareCard;