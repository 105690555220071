import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import "../css/UserMenu.css";
import UserContext from "./context/UserContext";
import {useHistory,withRouter} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import ConfirmContext from "./context/ConfirmContext";
import FavContext from "./context/FavContext";
import {apiConst} from "../constant/apiConst";
import AlertContext from "./context/AlertContext";
import {appSettings} from "../constant/appConst";
import {appUtil} from "../constant/appUtil";
import {useTranslation} from "react-i18next";
import ButtonWithLoading from "./ButtonWithLoading";

const UserMenu = (props) => {
    const { user, setUser } = useContext(UserContext);
    const { client, setClient } = useContext(ClientContext);
    const {confirm,setConfirm} = useContext(ConfirmContext);
    const {favs,setFavs} = useContext(FavContext);
    const {alert,setAlert} = useContext(AlertContext);
    const history = useHistory();
    const { t } = useTranslation();
    const [isLoading,setIsLoading] = useState(false);
    const [isLoginClick,setIsLoginClick] = useState(false);
    function onClickLogout(){
        setConfirm({show:true,text:t("terminate_session"),onConfirm : onConfirm})
    }
    function onConfirm(){
        if(client) apiConst.presentationTerminate(sessionStorage.getItem("presentation_id"));
        setUser(null);
        setClient(null);
        setFavs([]);
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("presentation_id");
        sessionStorage.removeItem("presentation_status");
        sessionStorage.removeItem("client");
        sessionStorage.removeItem("favs");
        history.push("/");
        if(client) setAlert("Activity is terminated");
    }

    function onCloseBtnClick() {
        document.querySelector(".user-menu").classList.add("d-none")
    }



    useEffect(() => {
        if(user && isLoginClick)
        {
            if(client){
                apiConst.presentationTerminate(sessionStorage.getItem("presentation_id"));
                setClient(null);
                setFavs([]);
            }

            sessionStorage.removeItem("user");
            sessionStorage.removeItem("presentation_id");
            sessionStorage.removeItem("presentation_status");
            sessionStorage.removeItem("client");
            sessionStorage.removeItem("favs");
            sessionStorage.setItem("user",JSON.stringify(user));
            setAlert(t("hello") + " " + user.name);
            history.push("/");
        }

    },[user,isLoginClick]);

    function onClickLoginButton(userId){
        setIsLoginClick(true)
        setIsLoading(true);
        apiConst.getUser(userId).then(result => {
            setIsLoading(false);
            setUser(result)
        })
    }

    function onClickDashboard(){
        document.querySelector(".user-menu").classList.add("d-none")
        history.push("/dashboard");
    }


    return (
        <div className={"user-menu d-none"}>
            <button className={"close-button colored"} onClick={onCloseBtnClick}>
                <div></div>
                <div></div>
            </button>
            <div className="sub-user-menu ">
                <div className={"sub-user-menu-icon-container"}>
                    <div className="sub-user-menu-icon"></div>
                </div>
                <span className={"user-online"}><span className={"user-online-indicator"}></span>{t("online")}</span>
                <div className={"mt-3"}>
                    <div className="user-menu-role"></div>
                    <div className="user-menu-name">{user.name}</div>
                </div>
                <div className={"mt-3"}>
                    <ButtonWithLoading className={"default-button text-center lined d-block w-100"} onClick={() => onClickLoginButton(2)} label="LOGIN AS SALES REP" isLoading={isLoading} />
                    <ButtonWithLoading className={"default-button text-center lined d-block w-100 mt-2"} onClick={() => onClickLoginButton(3)} label="LOGIN AS SALES MANAGER" isLoading={isLoading} />
                    <ButtonWithLoading className={"default-button text-center lined d-block w-100 mt-2"} onClick={() => onClickLoginButton(4)} label="LOGIN AS GENERAL MANAGER" isLoading={isLoading} />

                </div>
            </div>
            <div
                className={appUtil.isSelfPresentation() ? "user-menu-link-container d-none" : "user-menu-link-container"}>
                <div onClick={onClickDashboard} className={"user-menu-link dashboard-link"}>
                    <button
                        className={appUtil.isSelfPresentation() ? "default-button btn-disabled" : "default-button"}>{t("dashboard")}
                    </button>
                </div>
                <div onClick={onClickLogout} className={"user-menu-link logout-link"}>
                    <button
                        className={appUtil.isSelfPresentation() ? "default-button btn-disabled" : "default-button"}>{t("logout")}</button>
                </div>
            </div>
        </div>
    );
};

export default withRouter(UserMenu);