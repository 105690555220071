import React, {useState, useContext} from 'react';
import '../../css/Modal.css';
import AppDataContext from "../context/AppDataContext";
import {appConst} from "../../constant/appConst";
import ButtonWithLoading from "../ButtonWithLoading";
import {appUtil} from "../../constant/appUtil";
import {apiConst} from "../../constant/apiConst";

function ModalEditProperty(props) {
  
  const {appData,setAppData} = useContext(AppDataContext);
  const modalClass = "edit-property";
  const [fields, setFields] = useState({
    price: props.property.price,
    status_id: props.property.status.id
  });
  const [isLoading,setIsLoading] = useState(false);

  function hideModal(){

    appConst.closeModal(modalClass);
    props.clearActive();

  }

  function onSave(){

    setIsLoading(true);
    apiConst.propertyUpdatePriceOrStatus(props.property.id,fields).then(result => {
      setIsLoading(false);

      if(result.response)
      {
        let property = {
          id: result.property.id,
          name: result.property.code,
          floorType: result.property.room_type.name,
          netArea: result.property.net_area,
          price: result.property.price,
          status: result.property.status
        }
        props.onConfirm(property);
        hideModal();
      }
    })

  }

  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }

  return (

    <div className={"modal " + modalClass + " fade"} id="testModal" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">{props.modalTitle}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h3 className="modal-body-title">{props.title}</h3>
            
            <div className="form-group">
            

              <label htmlFor="status">Status</label>
              <select className={"form-control"} id="status"  defaultValue={props.property.status} onChange={(e) => handleChange("status",e)}>
                { appData.statuses && appData.statuses.map( l => (
                    <option key={l.id} value={l.id}>{l.name}</option>
                ))}
              </select>
            

            </div>
            
            
            
          </div>
          <div className="modal-footer">
            <button type="button" className="default-button secondary" data-dismiss="modal" onClick={hideModal}>{props.cancelText}</button>
            <ButtonWithLoading className={"default-button "} onClick={onSave} label={props.confirmText} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>

  );
  
}

export default ModalEditProperty;