import React, {useContext, useEffect, useState} from 'react';
import SplashScreen from "./SplashScreen";
import MainMenu from "./MainMenu";
import Alert from "./Alert";

import PropertyView from "./PropertyView";
import FloorView from "./FloorView";
import BuildingView from "./BuildingView";
import Gallery from "./Gallery";
import Location from "./Location";
import Brochure from "./Brochure";
import ListView from "./ListView";
import GridView from "./GridView";
import Home from "./Home";
import UserContext from "./context/UserContext";
import UserPrivateRoute from "./UserPrivateRoute";
import Confirm from "./Confirm";

import IntroVideo from "./IntroVideo";
import FacilitiyView from "./FacilitiyView";
import ClientLogin from "./ClientLogin";
import ClientContext from "./context/ClientContext";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import ClientPrivateRoute from "./ClientPrivateRoute";
import ClientRegister from "./ClientRegister";
import ClientVerification from "./ClientVerification";
import ClientForgotPassword from "./ClientForgotPassword";
import UserLogin from "./UserLogin";
import FormResult from "./FormResult";
import ClientResetPassword from "./ClientResetPassword";

import ClientVerificationDirectly from "./ClientVerificationDirectly";
import ClientInvite from "./ClientInvite";
import ClientInvitationWait from "./ClientInvitationWait";
import ClientInvitationAccept from "./ClientInvitationAccept";
import SessionTimeout from "./SessionTimeout";
import {appSettings} from "../constant/appConst";
import UserForgotPassword from "./UserForgotPassword";
import UserResetPassword from "./UserResetPassword";
import AppDataContext from "./context/AppDataContext";
import Favorites from "./Favorites";
import FilterContext from "./context/FilterContext";
import MasterPlanView from "./MasterPlanView";
import ClientLoginWait from "./ClientLoginWait";
import ClientLoginAccept from "./ClientLoginAccept";
import Viewer360 from "./Viewer360";
import RealShot from "./RealShot";
import DashboardHome from "./dashboard/DashboardHome";
import SalesReps from "./dashboard/SalesReps";
import SalesRep from "./dashboard/SalesRep";
import SalesManagers from "./dashboard/SalesManagers";
import SalesManager from "./dashboard/SalesManager";
import PropertyActions from "./dashboard/PropertyActions";
import Properties from "./dashboard/Properties";
import Property from "./dashboard/Property";
import Clients from "./dashboard/Clients";
import Client from "./dashboard/Client";
import Brokers from "./dashboard/Brokers";
import Broker from "./dashboard/Broker";
import GeneralManagers from "./dashboard/GeneralManagers";



const Routes = ({is360Loaded,alert}) => {
    const location = useLocation();
    const { user, setUser } = useContext(UserContext);
    const { client, setClient } = useContext(ClientContext);
    const { appData, setAppData } = useContext(AppDataContext);
    const { filter, setFilter } = useContext(FilterContext);
    useEffect(() => {

        if(location.pathname !== "/" && location.pathname !== "/map-view")
            document.getElementById("container360").classList.add("d-none")
        else
            document.getElementById("container360").classList.remove("d-none");



    },[location])






    return (

        <div>
            {client && <SessionTimeout />}
            <Alert />
            <Confirm />
            {appSettings.showIntroVideo && <IntroVideo /> }
            <SplashScreen loaded360={is360Loaded}/>
            <MainMenu/>

            <Home loaded360={is360Loaded} />

            {/*{appData.isFilterModeActive && <button id="exit-filter-mode" onClick={onClickExitFilterMode} className={"default-button"}>EXIT FILTER MODE</button>}*/}
            <Switch>

                <UserPrivateRoute component={Property} path="/dashboard/property/:property_id" exact />
                <UserPrivateRoute component={Properties} path="/dashboard/properties" exact />
                <UserPrivateRoute component={PropertyActions} path="/dashboard/property-actions" exact />
                <UserPrivateRoute component={SalesManager} path="/dashboard/sales-manager/:user_id" exact />
                <UserPrivateRoute component={SalesManagers} path="/dashboard/sales-managers" exact />
                <UserPrivateRoute component={SalesRep} path="/dashboard/sales-rep/:user_id" exact />
                <UserPrivateRoute component={SalesReps} path="/dashboard/sales-reps" exact />
                <UserPrivateRoute component={Client} path="/dashboard/client/:client_id" exact />
                <UserPrivateRoute component={Clients} path="/dashboard/clients" exact />
                <UserPrivateRoute component={Broker} path="/dashboard/broker/:user_id" exact />
                <UserPrivateRoute component={Brokers} path="/dashboard/brokers" exact />
                <UserPrivateRoute component={GeneralManagers} path="/dashboard/general-managers" exact />
                <UserPrivateRoute component={DashboardHome} path="/dashboard" />

                <Route component={PropertyView} path="/property-view/:property_id" exact />
                <Route component={FloorView} path="/building/:building_id/floor/:floor_id" exact />
                <Route component={BuildingView} path="/building/:id" exact />
                <Route component={Gallery} path="/gallery/:id" exact />
                <Route component={FacilitiyView} path="/facility/:id" exact />
                <Route component={Location} path="/location/:category/:id" exact />
                <Route component={Brochure} path="/brochure" exact />
                <Route component={Home} path="/map-view" exact />
                <Route component={ListView} path="/list-view" exact />
                <Route component={GridView} path="/grid-view" exact />
                <Route component={Favorites} path="/favorites" exact />

                <Route path="/login" component={ClientLogin} exact />
                <Route path="/salesLogin" component={UserLogin} exact />
                <Route path="/clientInvitationWait/:id" component={ClientInvitationWait} exact />
                <Route path="/clientInvitationAccept/:id/:code" component={ClientInvitationAccept} exact />
                <Route path="/register" component={ClientRegister} exact />
                <Route path="/inviteClient" component={ClientInvite} exact />
                <Route path="/userForgotPassword" component={UserForgotPassword} exact />
                <Route path="/clientResetPassword/:id/:token" component={ClientResetPassword} exact />
                <Route path="/userResetPassword/:id/:token" component={UserResetPassword} exact />
                <Route path="/formResult/:message" component={FormResult} exact />
                <Route path="/verify/:id" component={ClientVerification} exact />
                <Route path="/verify/:id/:code" component={ClientVerificationDirectly} exact />
                <Route path="/clientLoginWait/:id" component={ClientLoginWait} exact />
                <Route path="/clientLoginAccept/:id/:code" component={ClientLoginAccept} exact />
                <Route path="/realshot" component={RealShot} exact />


            </Switch>
        </div>



    );
};

export default Routes;