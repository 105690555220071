import React, {useContext, useEffect, useState} from 'react';
import '../../css/dashboard/SalesReps.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import {apiConst} from "../../constant/apiConst";
import {appConst} from "../../constant/appConst";
import UserContext from "../context/UserContext";
import ModalEditNote from "./ModalEditNote";
import {appUtil} from "../../constant/appUtil";
import ModalEditSalesPerson from "./ModalEditSalesPerson";



function SalesReps() {
    const {user,setUser} = useContext(UserContext);
    const [data1,setData1] = useState(null);
    const [salesReps,setSalesReps] = useState(null);
    const [topSalesReps,setTopSalesReps] = useState({
        salesRep1 : {total:-1,name:"No One"},
        salesRep2 : {total:-1,name:"No One"},
        salesRep3 : {total:-1,name:"No One"},
    });
    const [activePerson,setActivePerson] = useState(null);
    useEffect(() => {
        apiConst.getUser(user.id).then(user => {

            if(user.role.id === appConst.role.sales_manager)
            {
                console.log(user)
                setSalesReps(user.children);
            }

            else
                setSalesReps(user.director.sales_reps);

        })
    },[])

    useEffect(() => {
        if(salesReps)
        {
            let data_1 = {
                className: "sales-reps",
                title: "Sales Reps",
                headings: [],
                rows: []
            };
            salesReps.forEach(salesRep => {
                data_1.rows.push({
                    salesRep: {id: salesRep.id, name: salesRep.name}
                })
            })
            setData1(data_1);

            salesReps.sort((a, b) => (a.totals.sold < b.totals.sold) ? 1 : -1);

            let data_2 = {...topSalesReps}

            if(salesReps.length >= 1)
            {
                data_2.salesRep1.name = salesReps[0].name
                data_2.salesRep1.total = salesReps[0].totals.sold
            }
            if(salesReps.length >= 2)
            {
                data_2.salesRep2.name = salesReps[1].name
                data_2.salesRep2.total = salesReps[1].totals.sold
            }
            if(salesReps.length >= 3)
            {
                data_2.salesRep3.name = salesReps[2].name
                data_2.salesRep3.total = salesReps[2].totals.sold
            }

            setTopSalesReps(data_2);
        }
    },[salesReps])

    useEffect(() => {
        if(activePerson) appConst.showModal("edit-person");
    },[activePerson])

    function addPerson(){
        setActivePerson({})
    }

    function savePerson(process,savedPerson){

        let data = {
            className: "sales-reps",
            title: "Sales Reps",
            headings: [],
            rows: []
        };
        if(process === "create")
        {
            data.rows = [{salesRep : {id: savedPerson.id, name: savedPerson.name}},...data1.rows];
            setData1(data);

        }

    }
  

  return (

    <section className="primary dashboard sales-reps">
      <DashboardHeader slug="sales-reps" />
  
        <div className="container">
        <div className="row">
          
        <div className="col-4">
          <DashboardTable data={data1} headerButton={{title: "Add"}} onHeaderButton={addPerson} height="h-80" />
         </div>

         <div className="col-8">
            <div className="dashboard-box best-sales-reps">
              <h3 className="text-center">Best Sales Reps</h3>

              <div className="row">
                
                <NumberBox className="number-box" number={topSalesReps.salesRep1.total} text={topSalesReps.salesRep1.name} />
                <NumberBox className="number-box" number={topSalesReps.salesRep2.total} text={topSalesReps.salesRep2.name} />
                <NumberBox className="number-box" number={topSalesReps.salesRep3.total} text={topSalesReps.salesRep3.name} />

              </div>

            </div>

           
            

         </div>
          
        </div>

        

      </div>
        {activePerson && <ModalEditSalesPerson title="Add Sales Rep" person={activePerson} onConfirm={savePerson} confirmText="Save" cancelText="Cancel" role={appConst.role.sales_rep} />}

    </section>

  );
  
}

export default SalesReps;