import  React, {useState, useEffect, useContext} from 'react';
import ListItem from './ListItem';
import '../css/ListView.css';
import AppDataContext from "./context/AppDataContext";
import GridItem from "./GridItem";
import {appUtil} from "../constant/appUtil";
import LoadingOverlay from "./LoadingOverlay";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


function ListView() {

    const {appData, setAppData} = useContext(AppDataContext);
    const [listItems,setListItems] = useState(null);
    const [sortBy,setSortBy] = useState("");
    const { t } = useTranslation();

    useEffect(()=> {
        if(appData.loaded)
        {
            if(!sortBy) {
                let rows = [];
                appData.filteredProperties.forEach(function(property){
                    rows.push(<ListItem key={property.id} property={property} />);
                })
                setListItems(rows)
            }
            else {
                sort(sortBy,true)
            }



        }
    },[appData.loaded,appData.filteredProperties])

    function sort(type,isFilterChanged = false){

        if(type === sortBy && !isFilterChanged) { type = "-" + type }
        let sorted = appData.filteredProperties.sort(appUtil.dynamicSort(type));
        let rows = [];
        sorted.forEach(function(property){
            rows.push(<ListItem key={property.id} property={property} />);
        })
        setListItems(rows)
        setSortBy(type)
    }



    return (
        <section className={listItems ? "primary narrow list-view " :"primary narrow list-view loading"}>
            <LoadingOverlay loaded={!!listItems}/>
            <div className="inner">
                <table className="table table-hover">
                    <thead className="thead-dark">
                    <tr>
                        <th className={"text-uppercase"}>{t("filter.status")}</th>
                        <th><button className={"btn-list-sort text-uppercase"} onClick={()=> {sort("code")}}>{ sortBy === "code" &&<span className={"icon icon-up"}></span>}{ sortBy === "-code" &&<span className={"icon icon-down"}></span>}{t("filter.number")}</button></th>
                        <th><button className={"btn-list-sort text-uppercase"} onClick={()=> {sort("net_area")}}>{ sortBy === "net_area" &&<span className={"icon icon-up"}></span>}{ sortBy === "-net_area" &&<span className={"icon icon-down"}></span>}{t("filter.net_area")}</button></th>
                            <th><button className={"btn-list-sort text-uppercase"} onClick={()=> {sort("garden_area")}}>{ sortBy === "garden_area" &&<span className={"icon icon-up"}></span>}{ sortBy === "-garden_area" &&<span className={"icon icon-down"}></span>}{t("filter.garden_filter")}</button></th>
                        <th><button className={"btn-list-sort text-uppercase"} onClick={()=> {sort("terrace_area")}}>{ sortBy === "terrace_area" &&<span className={"icon icon-up"}></span>}{ sortBy === "-terrace_area" &&<span className={"icon icon-down"}></span>}{t("filter.balcony_area")}</button></th>
                        <th><button className={"btn-list-sort text-uppercase"} onClick={()=> {sort("bathrooms")}}>{ sortBy === "bathrooms" &&<span className={"icon icon-up"}></span>}{ sortBy === "-bathrooms" &&<span className={"icon icon-down"}></span>}{t("filter.bath")}</button></th>
                        <th><button className={"btn-list-sort text-uppercase"} onClick={()=> {sort("price")}}>{ sortBy === "price" &&<span className={"icon icon-up"}></span>}{ sortBy === "-price" &&<span className={"icon icon-down"}></span>}{t("filter.price")}</button></th>


                    </tr>
                    </thead>

                    <tbody >
                    {listItems}

                    </tbody>
                </table>
            </div>
        </section>
    )

}

export default ListView;