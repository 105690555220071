export const buildingConst = {
    "type_b": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1445.03 769.51 1445.03 597.17 1831.55 597.17 1831.55 770.51 1445.03 769.51"},
            {status : 1, level: 1,points:"1445.03 769.51 1445.03 597.17 936.26 597.17 936.26 770.51 1445.03 769.51"},
            {status : 1, level: 2,points:"84.67 597.17 84.67 769.51 878.3 770.51 878.3 597.17 84.67 597.17"},
            {status : 1, level: 3,points:"1479.57 335.15 1825.29 335.15 1825.48 363.92 1831.55 364.59 1831.55 597.17 1445.03 597.17 1445.03 352.32 1479.57 335.15"},
            {status : 1, level: 4,points:"936.26 315.97 1445.03 317.97 1445.03 597.17 936.26 597.17 936.26 315.97"},
            {status : 1, level: 5,points:"84.67 331.85 878.3 330.85 878.3 597.17 84.67 597.17 84.67 331.85"},

        ]
    },
    "type_a": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1787.06 769.57 1787.06 619.58 1439.45 619.58 1439.45 768.57 1787.06 769.57"},
            {status : 1, level: 1,points:"1439.45 768.57 1439.45 619.58 927.41 619.58 927.41 767.57 1439.45 768.57"},
            {status : 1, level: 2,points:"887.25 768.57 887.25 619.58 89.74 619.58 89.74 769.57 887.25 768.57"},
            {status : 1, level: 3,points:"1787.06 390.81 1781.16 391.04 1781.7 363.19 1449.17 361.81 1438.67 366.72 1439.45 619.58 1787.06 619.58 1787.06 390.81"},
            {status : 1, level: 4,points:"926.41 328.46 927.41 619.58 1439.45 619.58 1438.45 331.48 926.41 328.46"},
            {status : 1, level: 5,points:"885.32 442.17 896.8 442.93 873.64 354.26 89.74 354.26 89.74 619.58 887.25 619.58 885.32 442.17"},
        ]
    },
    "type_u": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1751.24 836.99 1751.24 617.32 1177.51 635.46 1177.51 817.69 1751.24 836.99"},
            {status : 1, level: 1,points:"1148.22 814.18 1148.22 635.46 1081.55 635.46 595.59 656.29 595.59 793.13 1085.06 814.18 1148.22 814.18"},
            {status : 1, level: 2,points:"156.99 772.08 156.99 673.83 595.59 656.29 595.59 793.13 156.99 772.08"},
            {status : 1, level: 3,points:"1751.24 244.41 1751.24 617.32 1177.51 635.46 1177.51 334.58 1184.95 332.81 1184.78 294.91 1744.57 199.42 1742.99 245.16 1751.24 244.41"},
            {status : 1, level: 4,points:"595.09 368.57 1082.3 278.1 1177.01 394.89 1177.01 412.43 1149.47 416.43 1147.72 635.46 1081.05 635.46 595.09 656.29 595.09 368.57"},
            {status : 1, level: 5,points:"156.99 477.34 595.59 396.64 595.59 656.29 156.99 673.83 156.99 477.34"},
        ]
    },
    "type_r": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1693.55 652.17 1120.36 661.17 1120.36 873.37 1693.55 904.67 1693.55 652.17"},
            {status : 1, level: 1,points:"519.3 841.02 519.3 661.17 1101.25 660.41 1101.25 873.82 519.3 841.02"},
            {status : 1, level: 2,points:"519.3 840.96 519.3 670.73 59.98 670.73 59.98 805.92 519.3 840.96"},
            {status : 1, level: 3,points:"1693.55 266.49 1686.92 266.93 1687.56 220.01 1128.05 303.64 1127.54 342.81 1120.36 343.78 1120.36 661.17 1693.55 652.17 1693.55 266.49"},
            {status : 1, level: 4,points:"519.3 661.17 1101.25 660.41 1100.93 417.3 1120.36 416.3 1120.36 379.91 1020.06 288.24 519.3 369.12 519.3 661.17"},
            {status : 1, level: 5,points:"519.3 392.71 59.98 463.83 59.98 670.73 519.3 670.73 519.3 392.71"},
        ]
    },
    "type_t": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1685.08 769.31 1685.08 605.88 1208.49 610.51 1209.49 770.86 1685.08 769.31"},
            {status : 1, level: 1,points:"278.73 772.52 279.73 610.51 1208.49 610.51 1209.49 770.86 278.73 772.52"},
            {status : 1, level: 2,points:"1277.79 293.34 1208.49 377.7 1208.49 610.51 1685.08 605.88 1685.08 297.75 1277.79 293.34"},
            {status : 1, level: 3,points:"279.73 610.51 1208.49 610.51 1208.49 293.03 279.73 280.69 279.73 610.51"},
        ]
    },
    "type_s": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1154.52 622.21 1154.94 820.24 1162.26 824.79 1681.39 830.44 1681.39 618.44 1154.52 622.21"},
            {status : 1, level: 1,points:"127.95 631.83 127.95 812.85 1083.8 823.85 1083.8 626.76"},
            {status : 1, level: 2,points:"1165.49 310.61 1148.66 386.53 1152.81 388.35 1154.52 622.21 1681.39 618.44 1681.39 290.69 1165.49 310.61"},
            {status : 1, level: 3,points:"127.95 631.83 127.95 312.76 1083.8 271.74 1083.8 626.76 127.95 631.83"},
        ]
    },
    "type_e": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1597.79 888.86 951.06 867.86 951.06 645.55 1596.79 623.12"},
            {status : 1, level: 1,points:"173.97 832 173.97 669.33 868.84 645.55 868.84 864.86 173.97 832"},
            {status : 1, level: 3,points:"1596.79 623.12 951.06 645.55 951.06 269.31 1597.79 140.71"},
            {status : 1, level: 4,points:"173.97 392.7 868.84 238.22 868.84 645.55 173.97 669.33 173.97 392.7"},
        ]
    },

    "type_h": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"986.06 808.11 986.06 623.1 1500.14 585.39 1645.74 653.64 1645.72 659.57 1662.09 667.91 1662.09 792.92 1560.05 835.32 1500.14 832.89 986.06 808.11"},
            {status : 1, level: 1,points:"355.36 779.57 355.56 659.41 919.97 628.74 986.06 635.1 986.06 808.11 355.36 779.57"},
            {status : 1, level: 2,points:"986.06 264.15 1501.54 137.47 1607.76 340.68 1606.64 361.36 1616.3 361.07 1646.04 416.13 1646.04 468.26 1664.59 498.23 1662.53 642.29 1645.74 642.29 1645.74 653.64 1500.14 585.39 986.06 623.1 986.06 264.15"},
            {status : 1, level: 3,points:"355.36 422.7 920.49 282.28 986.06 315.5 986.06 635.1 920.49 628.91 355.56 659.41 355.36 422.7"},
        ]
    },
    "type_f": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"960 846.85 1447.31 866.55 1697.24 774.37 1697.24 682.05 1681.08 677.63 1681.52 670.12 1447.31 614.05 960 644.15 960 846.85"},
            {status : 1, level: 1,points:"369.18 820.02 369.18 678.18 899.07 643.78 960 658.49 960 846.85 369.18 820.02"},
            {status : 1, level: 2,points:"960 286.57 1449.31 160 1616.68 346.44 1616.68 366.99 1631.93 365.99 1681.29 418.99 1681.29 491.18 1697.24 503.18 1696.1 655.99 1680.99 654.78 1681.52 670.12 1447.31 614.05 960 644.15 960 286.57"},
            {status : 1, level: 3,points:"369.18 442.27 899.07 304.17 960 329.85 960 658.49 899.07 643.78 369.18 678.18 369.18 442.27"},
        ]
    },
    "type_g": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"911.02 810.28 911.02 582.78 1509.4 535.5 1509.4 817.82 911.02 810.28"},
            {status : 1, level: 1,points:"183.95 780.54 183.95 635.3 835.1 581.18 911.02 612.39 911.02 810.28 183.95 780.54"},
            {status : 1, level: 2,points:"911.02 183.62 1508.95 26.01 1509.4 535.5 911.02 582.78 911.02 183.62"},
            {status : 1, level: 3,points:"184.95 375.78 835.1 203.61 911.02 264.15 911.02 612.39 835.1 581.18 183.95 635.3 184.95 375.78"},
        ]
    },
    "type_i": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"736.6 810.33 736.6 617.15 1551.17 573.12 1551.17 853.78 736.6 810.33"},
            {status : 1, level: 1,points:"173.63 780.31 173.63 655.56 681.04 625.15 736.6 638.76 736.6 810.33 173.63 780.31"},
            {status : 1, level: 2,points:"737.6 293.57 1551.17 100.34 1551.17 573.12 736.6 617.15 737.6 293.57"},
            {status : 1, level: 3,points:"173.63 429.11 681.04 306.45 737.6 333.09 736.6 638.76 681.04 625.15 173.63 655.56 173.63 429.11"},
        ]
    },
    "type_l": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"816.42 832.13 816.42 672.58 1454.19 656.63 1584.55 683.29 1585.89 796.89 1454.19 909.46 816.42 832.13"},
            {status : 1, level: 1,points:"327.67 783.33 327.67 684.99 769.64 672.29 816.42 681.35 816.42 832.13 327.67 783.33"},
            {status : 1, level: 2,points:"1454.19 656.63 816.42 672.58 816.42 401.7 1454.19 289.8 1536.48 420.04 1536.68 433.49 1545.21 433.57 1570.53 472.56 1570.53 526.68 1584.55 543.37 1584.55 683.29 1454.19 656.63"},
            {status : 1, level: 3,points:"769.64 409.75 327.67 487.31 327.67 684.99 769.64 672.35 816.42 681.35 816.42 424.97 769.64 409.75"},
        ]
    },
    "type_j": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"782.63 844.75 782.63 622.27 1654.05 590.4 1654.05 911.19 782.63 844.75"},
            {status : 1, level: 1,points:"137.55 794.56 137.55 646.28 720.78 626.25 782.63 646.28 782.63 844.75 137.55 794.56"},
            {status : 1, level: 2,points:"782.63 275.67 1654.05 114.74 1654.05 590.4 782.63 622.27 782.63 275.67"},
            {status : 1, level: 3,points:"720.78 287.32 137.55 395.24 137.55 646.28 720.78 626.25 782.63 646.28 782.63 314.54 720.78 287.32"},
        ]
    },

    "type_k": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"606.02 855.76 606.02 683.82 1091.15 693.82 1636.13 677.82 1638.13 837.14 1091.15 950.22 606.02 855.76"},
            {status : 1, level: 1,points:"299.87 794.83 299.87 678.18 575.39 681.18 606.02 679.82 606.02 855.76 299.87 794.83"},
            {status : 1, level: 2,points:"606.02 383.47 1091.15 224.5 1636.13 404.02 1636.13 677.82 1091.15 693.82 606.02 683.82 606.02 383.47"},
            {status : 1, level: 3,points:"575.39 393.58 299.87 484.36 299.87 678.18 575.39 681.18 606.02 679.82 606.02 397.46 575.39 393.58"},
        ]
    },
    "type_p": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1597.48 629.57 1704.84 683.81 1704.77 693.97 1709.41 694.42 1718.92 699.28 1718.68 790.47 1707.86 790.15 1708.2 846.14 1670.27 897.93 1669.27 970.96 742.19 880.64 742.19 671.06 1597.48 629.57"},
            {status : 1, level: 1,points:"185.75 826.03 185.75 688.73 685.96 675.81 742.19 683.73 742.19 880.64 185.75 826.03"},
            {status : 1, level: 2,points:"742.19 329.56 1595.48 100.56 1678.49 370.19 1698.37 431.59 1698.37 486.81 1708.08 486.81 1718.54 522.03 1719.92 671.06 1704.99 670.81 1704.84 683.81 1597.48 629.57 742.19 671.06 742.19 329.56"},
            {status : 1, level: 3,points:"688.97 342.12 186.75 474.9 185.75 688.73 685.96 675.81 742.19 683.73 742.19 361.36 688.97 342.12"},
        ]
    },

    "type_o": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"885.16 786.24 885.16 592.6 1799.77 530.22 1799.77 822.87 885.16 786.24"},
            {status : 1, level: 1,points:"226.3 753.54 226.3 635.4 825.08 593.07 885.16 604.16 885.16 786.24 226.3 753.54"},
            {status : 1, level: 2,points:"885.16 252.29 1799.77 17.2 1799.77 530.22 885.16 592.6 885.16 252.29"},
            {status : 1, level: 3,points:"825.08 266.81 226.3 424.38 226.3 635.4 825.08 593.07 885.16 604.16 885.16 299.08 825.08 266.81"},
        ]
    },

    "type_n": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"946.48 748.12 946.48 572.49 1756.9 550.22 1756.9 762.33 946.48 748.12"},
            {status : 1, level: 1,points:"97.08 726.22 97.08 599.19 880.49 578.48 933.11 587.23 932.52 745.65 97.08 726.22"},
            {status : 1, level: 2,points:"946.48 260.06 1754.28 157.56 1756.9 550.22 946.48 572.49 946.48 260.06"},
            {status : 1, level: 3,points:"96.86 368.04 880.49 267.81 946.48 325.73 946.48 365.02 933.11 367.02 933.11 587.23 880.49 578.48 97.08 599.19 96.86 368.04"},
        ]
    },

    "type_m": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"583.77 848.79 583.77 658.83 1155.6 662.83 1676.73 658.83 1678.5 836.53 1154.6 952.31 583.77 848.79"},
            {status : 1, level: 1,points:"191.31 773.99 191.31 656.83 549.4 658.83 583.77 656.83 583.77 848.79 191.31 773.99"},
            {status : 1, level: 2,points:"583.77 337.43 1155.6 150.6 1678.73 372.34 1676.73 658.83 1155.6 662.83 583.77 658.83 583.77 337.43"},
            {status : 1, level: 3,points:"191.31 466.17 549.4 346.61 583.77 353.41 583.77 656.59 549.4 658.83 191.31 656.83 191.31 466.17"},
        ]
    },
    "type_c": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1601 845.67 1601 645.14 1734.16 640.41 1735.16 793.02 1601 845.67"},
            {status : 1, level: 1,points:"1225.12 657.41 1601 645.14 1601 845.67 1225.12 984.15 783.99 868.47 783.99 641.43 1225.12 657.41"},
            {status : 1, level: 2,points:"383.31 765.03 383.31 641.51 735.27 637.41 783.49 637.43 783.49 868.47 383.31 765.03"},
            {status : 1, level: 3,points:"1601 346.81 1734.16 430.51 1734.16 640.41 1601 645.14 1601 346.81"},
            {status : 1, level: 4,points:"783.99 285.06 1225.12 111.8 1601 346.81 1601 645.14 1225.12 657.41 783.99 641.43 783.99 285.06"},
            {status : 1, level: 5,points:"384.31 432.11 735.27 288.36 783.49 299.66 783.49 637.43 735.27 637.41 383.31 641.51 384.31 432.11"},
        ]
    },
    "type_d": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1784.06 762.88 1784.06 641.85 1769.63 642.29 1769.63 631.94 1630.48 633.77 1630.48 793.83 1640.62 795.57 1642.05 834.88 1784.06 762.88"},
            {status : 1, level: 1,points:"776.94 880.61 1105.58 964.75 1619.24 792.59 1630.48 793.83 1630.48 633.77 1237.21 642.29 776.94 634.13 776.94 880.61"},
            {status : 1, level: 2,points:"357.52 773.16 357.52 631.13 725.64 633.77 776.94 634.13 776.94 880.61 357.52 773.16"},
            {status : 1, level: 3,points:"1630.48 324.17 1769.63 410.25 1769.63 472.67 1784.06 480.97 1784.06 617.67 1769.63 617.78 1769.63 631.94 1630.48 633.77 1630.48 324.17"},
            {status : 1, level: 4,points:"776.94 258 1235.45 78.66 1630.48 324.17 1630.48 633.77 1237.21 642.29 776.94 634.13 776.94 258"},
            {status : 1, level: 5,points:"725.64 262.53 357.52 414.76 357.52 631.13 725.64 633.77 776.94 634.13 776.94 275.67 725.64 262.53"},
        ]
    },
    "type_v1": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1454.24 559.54 1570.86 545.41 1646.83 545.41 1646.83 555.42 1671.57 554.83 1810.56 552.48 1810.56 686.17 1591.47 686.17 1454.24 671.45 1454.24 559.54"},
            {status : 1, level: 1,points:"1153.88 547.77 1193.92 531.86 1266.95 531.86 1266.95 547.77 1290.51 540.5 1428.33 539.82 1428.33 673.8 1211.59 677.34 1159 666.15 1158.59 651.6 1153.88 649.8 1153.88 547.77"},
            {status : 1, level: 2,points:"829.95 511.25 898.27 511.25 905.92 520.09 905.92 525.97 922.42 525.97 922.42 518.61 1055.52 518.61 1055.52 594.29 1068.48 594.29 1068.48 652.6 829.95 652.6 829.95 511.25"},
            {status : 1, level: 3,points:"470.1 488.28 538.42 488.28 563.74 495.94 692.72 495.94 735.13 514.2 735.13 573.09 749.26 573.09 769.88 581.92 769.88 625.51 722.76 629.63 467.15 629.63 470.1 488.28"},
            {status : 1, level: 4,points:"104.94 540.5 119.08 540.5 119.08 472.38 186.22 472.38 230.98 481.21 335.82 481.21 430.64 500.65 430.64 558.96 467.15 563.67 467.15 605.48 254.28 605.48 254.39 599.45 104.94 597.68 104.94 540.5"},


        ]
    },
    "type_v2": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1270.34 543.79 1321.58 527 1445.26 525.24 1445.26 538.93 1526.1 525.24 1606.05 525.24 1606.05 534.51 1718.24 534.51 1718.24 729.75 1500.92 732.75 1300.05 734.73 1270.34 718.5 1270.34 543.79"},
            {status : 1, level: 1,points:"773.41 501.24 773.41 569.03 719.82 569.03 719.82 679.34 899.68 681.46 899.68 688.53 1147.22 688.53 1147.22 680.57 1142.51 677.61 1141.92 598.42 1089.68 597.83 1089.68 505.77 1024.13 506.36 1024.13 498.12 952.27 498.12 952.27 508.13 893.74 507.13 882.19 500.06 773.41 501.24"},
            {status : 1, level: 2,points:"186.81 540.5 293.82 540.5 293.82 478.86 388.23 478.86 415.62 483.27 450.19 483.27 450.19 476.44 512.26 475.44 534 481.51 544.6 482.39 625.11 503.59 625.11 570.74 650.61 570.74 701.2 577.8 701.2 632.16 624.99 635.34 527.82 636.34 527.93 652.2 313.14 652.45 186.81 649.61 186.81 540.5"},

        ]
    },

}
